import React from 'react'
import { Link } from 'react-router-dom';
// import './products.css'
import './CS_krishi.css'

import { GiShieldReflect } from "react-icons/gi";
import { GiRadarSweep } from "react-icons/gi";
import { MdAutoAwesome } from "react-icons/md";
import { GiPathDistance } from "react-icons/gi";
import { MdSpeed } from "react-icons/md";
import { IoSpeedometerOutline } from "react-icons/io5";
import { CiLineHeight } from "react-icons/ci";
import { TbRadar } from "react-icons/tb";
import { GiPlantWatering } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import { FaSprayCanSparkles } from "react-icons/fa6";

import k1 from '../assets/products/krishi.png'
// import f1 from '../assets/services/csk.png'

import appk2 from '../assets/Applications/fish_feed.png'
import appk3 from '../assets/Applications/pest_sray.jpg'
import appk4 from '../assets/Applications/seed_drop.png'

const CS_krishi = () => {
    const onButtonClick = () => {
        const pdfUrl = "CS-Krishi_Brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CS-Krishi_Brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <div className='CS_promain'>
                <div className='CS_proHeropage'>
                    <div className='Krishi'>
                        <div className='CS_proHeroimg'>
                            <img src={k1} class="img-fluid" alt="..." width={400}></img>
                        </div>
                        <h1>CS-KRISHI</h1>
                        <h5>Agriculture Drone</h5>
                        <div>
                            <button type="button" class="btn btn-primary" onClick={onButtonClick}>Download Brochure</button>
                            <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                        </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>CS-Krishi is an innovative agriculture spraying drone designed to revolutionize crop management practices. Its ability to access difficult-to-reach areas of fields ensures uniform coverage, promoting optimal crop health. Equipped with an intelligent navigation system and customizable spraying settings, it provides farmers with greater flexibility and control over their agricultural operations. This technology represents a promising solution for modernizing agriculture, enhancing efficiency, and minimizing environmental impact by precisely targeting inputs only where needed. CS-Krishi holds immense potential for improving crop yields and sustainability in farming practices.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={appk3} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Pesticide Spraying</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appk4} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Seed Dropping</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appk2} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Fish Feeding</figcaption>
                            </figure>


                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                        <h1><IoSpeedometerOutline /></h1>
                                        <h5>Flight Time 25 min</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiShieldReflect /></h1>
                                        <h5>Obstacle Avoidance</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiRadarSweep /></h1>
                                        <h5>Ground Radar</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdAutoAwesome /></h1>
                                        <h5>Auto Flight Mode</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiPathDistance /></h1>
                                        <h5>Spraying Pattern</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdSpeed /></h1>
                                        <h5>Flying Speed 0-8m/s</h5>
                                    </div>
                                </div>
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                        <h1><CiLineHeight /></h1>
                                        <h5>Operating Altitude 196.85 ft</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><TbRadar /></h1>
                                        <h5>Flying Radius 1KM</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><FaSprayCanSparkles /></h1>
                                        <h5>Spray Tank 12L</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiPlantWatering /></h1>
                                        <h5>Spraying Speed 0-5 L/min</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiWeight /></h1>
                                        <h5>Total Weight 29.98kg</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Sqaure'></div> */}
                </div>
            </div>
        </>
    )
}

export default CS_krishi