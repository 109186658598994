import React from 'react'
import { Link } from 'react-router-dom';
import './products.css'

import { MdSpeed } from "react-icons/md";
import { IoSpeedometerOutline } from "react-icons/io5";
import { GiWeight } from "react-icons/gi";
import { Tb360View } from "react-icons/tb";
import { FaVideo } from "react-icons/fa";
import { CiLineHeight } from "react-icons/ci";
import { LuArrowUpDown } from "react-icons/lu";
import { FaLocationDot } from "react-icons/fa6";
import { TbDrone } from "react-icons/tb";

import bee1 from '../assets/products/bee.png'
// import f1 from '../assets/services/csk.png'
// import { BiSolidChess } from "react-icons/bi";

import appbee1 from '../assets/Applications/live.jpg'
// import appbee2 from '../assets/Applications/mhi.png'
import appbee3 from '../assets/Applications/toy.jpg'

const CS_bee = () => {
    const onButtonClick = () => {
        const pdfUrl = "CS-Bee_Brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CS-Bee_Brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <div className='CS_promain'>
                <div className='CS_proHeropage'>
                    <div className='border'>
                        <div className='CS_proHeroimg'>
                            <img src={bee1} class="img-fluid" alt="..." width={400}></img>
                        </div>
                        <h1>CS-BEE</h1>
                        <h5>Toy Drone</h5>
                        <div>
                        <button type="button" class="btn btn-primary" onClick={onButtonClick}>Download Brochure</button>
                        <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                        </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>
                            The CS-BEE quadcopter emerges as a technological marvel. Its lightweight design sets the stage for an array of applications, showcasing its versatility in the field of live streaming, military home interventions, and as a toy drone for learning the fundamentals of drone flying. In live streaming scenarios, the CS-BEE takes center stage, offering agile and high-quality footage for events.
                            Meanwhile, its role in military home interventions is pivotal, providing a discreet and effective tool for reconnaissance and real-time intelligence gathering. Beyond its professional applications, the CS-BEE embraces the educational realm, serving as a user-friendly and accessible toy drone for enthusiasts keen on mastering the art of drone flying. With its adaptability and innovative features, the CS-BEE stands as a testament to the convergence of cutting-edge technology, catering to both serious surveillance needs and the joy of recreational drone enthusiasts.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={appbee3} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Toy Drone</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appbee1} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Live Streaming</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">
                                <div class="row justify-content-md-center">

                                    <div class="col col-lg-2">
                                        <h1><IoSpeedometerOutline /></h1>
                                        <h5>Flight Time 10 min</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdSpeed /></h1>
                                        <h5>Flying Speed 2-3m/s</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiWeight /></h1>
                                        <h5>Total Weight 80 gram</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><Tb360View /></h1>
                                        <h5>360 Flip</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><FaVideo /></h1>
                                        <h5>Video 720p</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                    <h1><CiLineHeight /></h1>
                                        <h5>Altitude Hold</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                    <h1><LuArrowUpDown /></h1>
                                        <h5>Auto Take-off & Land</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                    <h1><FaLocationDot /></h1>
                                        <h5>Auto Home</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                    <h1><TbDrone /></h1>
                                        <h5>Headless Mode</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CS_bee