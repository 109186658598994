import React from 'react'
import { Link } from 'react-router-dom';
import './products.css'

import { GiPathDistance } from "react-icons/gi";
import { MdSpeed } from "react-icons/md";
import { IoSpeedometerOutline } from "react-icons/io5";

import v1 from '../assets/products/vtol.png'
// import f1 from '../assets/services/csk.png'

import appvt1 from '../assets/Applications/secu_serv.jpg'
import appvt2 from '../assets/Applications/mapping.png'
import appvt3 from '../assets/Applications/delivery.jpeg'


const CS_vtol = () => {
    
    return (
        <>
            <div className='CS_promain'>
                <div className='CS_proHeropage'>
                    <div className='border'>
                        <div className='CS_proHeroimg'>
                            <img src={v1} class="img-fluid" alt="..." width={400}></img>
                        </div>
                        <h1>CS-VTOL</h1>
                        <h5>Mapping & Surveillence Drone</h5>
                        <div>
                        {/* <button type="button" class="btn btn-primary">Download Brochure</button> */}
                        <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                        </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>The VTOL drone is a game-changer in aerial technology due to its ability to vertically take off, hover, and land, combining the features of both multi-rotor and fixed-wing drones.
                            Its standout traits include long-range capabilities, high endurance, and a payload capacity of 2 to 5 kg. This drone excels in various tasks such as surveillance, mapping, photography, inspections, and delivery.
                            Its vertical takeoff and landing capabilities make it highly maneuverable in tight spaces, ideal for surveillance missions where advanced sensors provide real-time aerial perspectives.
                            Moreover, it excels in mapping applications by capturing high-resolution imagery and can efficiently transport small packages or essential supplies. Overall, this VTOL drone's innovation and adaptability make it a valuable asset across industries, enhancing security, advancing mapping, and optimizing logistical operations.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={appvt1} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Surveillence</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appvt2} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Mapping</figcaption>
                            </figure>
                            <figure class="figure">
                                <img src={appvt3} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Delivery</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                        <h1><IoSpeedometerOutline /></h1>
                                        <h5>Endurance 3 Hours</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiPathDistance /></h1>
                                        <h5>Operational Range 100 km</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><MdSpeed /></h1>
                                        <h5>Crucial Speed 70km/h</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Sqaure'></div> */}
                </div>
            </div>
        </>
    )
}

export default CS_vtol