import React from 'react'
import { Link } from 'react-router-dom';
import './products.css'

import p1 from '../assets/products/pavanputra.png'
// import f1 from '../assets/services/csk.png'
import { GiPathDistance } from "react-icons/gi";
import { GiWeight } from "react-icons/gi";
import { IoSpeedometerOutline } from "react-icons/io5";

import apppvn1 from '../assets/Applications/highaltd.jpeg'

const CS_pavanputra = () => {
    const onButtonClick = () => {
        const pdfUrl = "CS-Pavanputra_Brochure.pdf";
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "CS-Pavanputra_Brochure.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <>
            <div className='CS_promain'>
                <div className='CS_proHeropage'>
                    <div className='border'>
                        <div className='CS_proHeroimg'>
                            <img src={p1} class="img-fluid" alt="..." width={400}></img>
                        </div>
                        <h1>CS-PAVANPUTRA</h1>
                        <h5>High Payload Delivery Drone</h5>
                        <div>
                        <button type="button" class="btn btn-primary" onClick={onButtonClick}>Download Brochure</button>
                        <Link to='/contactus'><button type="button" class="btn btn-primary">Enquire</button></Link>
                        </div>
                    </div>
                </div>
                <div className='CS_prodata'>
                    <div className='CS_proInfo'>
                        <h1>Information</h1>
                        <p>The "Pavanputra" hexacopter, distinguished by its indigenous design, represents a significant stride in technological self-sufficiency for defense applications. Developed as a heavy-duty drone, it proudly shoulders the responsibility of carrying a substantial payload of 30 kg, exemplifying its robust capabilities.
                            The indigenous design is not just a mark of national pride but ensures that the hexacopter is tailored to meet the specific requirements and challenges faced by defense services. Pavanputra's role in aerial delivery for defense is crucial, offering a reliable and powerful solution for transporting heavy loads such as equipment, supplies, or emergency aid. This hexacopter emerges as a symbol of innovation and strength, embodying the spirit of self-reliance in defense technology and providing a versatile and effective tool for the diverse needs of defense services.</p>
                    </div>
                    <div className='CS_proApplication'>
                        <div className='CS_application'>
                            <h1>Applications</h1>
                            <figure class="figure">
                                <img src={apppvn1} class="figure-img img-fluid rounded" alt="..." />
                                <figcaption class="figure-caption">Higher Altitude Payload Delivery</figcaption>
                            </figure>
                        </div>
                    </div>
                    <div className='CS_proFeature'>
                        <div className='CS_Feature'>
                            <h1>Features</h1>
                            <div class="container text-center">
                                <div class="row justify-content-md-center">
                                    <div class="col col-lg-2">
                                        <h1><IoSpeedometerOutline /></h1>
                                        <h5>Flight Time 25 min</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiPathDistance /></h1>
                                        <h5>Operation Range  5 km</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                        <h1><GiWeight /></h1>
                                        <h5>AUW 102kg</h5>
                                    </div>
                                    <div class="col col-lg-2">
                                    <h1><GiWeight /></h1>
                                        <h5>3o kg Payload Delivery</h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className='Sqaure'></div> */}
                </div>
            </div>
        </>
    )
}

export default CS_pavanputra